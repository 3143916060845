import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import GlobalContext from '../../context/GlobalContext';
import config from '../../config/config';
import Axios from 'axios';

import Tooltip from '../../components/Tooltip/Tooltip';
import Blocker from '../../components/Blocker/Blocker';

import photo1 from '../../assets/products/TWA-130faceplate-1-min.png';

import { AiOutlineSearch as SearchIcon } from 'react-icons/ai';
import { AiFillDelete as DeleteIcon } from 'react-icons/ai';
import { AiFillEdit as EditIcon } from 'react-icons/ai';
import { IoMdAdd as AddIcon } from 'react-icons/io';
import { AiOutlineCloseSquare as CloseIcon } from 'react-icons/ai';
import { AiOutlineCaretUp as UpIcon } from 'react-icons/ai';
import { AiOutlineCaretDown as DownIcon } from 'react-icons/ai';


import './Products.scss';


class ProductsScreen extends Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.state = {
            categories: [],
            products: [],

            categoryEditorMode: '',
            editorCategoryName: '',
            editorCategoryShortName: '',
            editorCategoryImage: '',

            // current
            currentCategoryIndex: -1,
            currentSubcategoryIndex: -1,

            searchPhraseInput: '',
            searchPhrase: '',
            useSearch: false,

            limit: 16,
            skip: 0,
            totalCount: 0,
            showCategories: false,
        }

        this.resolver = null;
    }

    async componentDidMount() {
        window.scroll(5, 0, 0);



        await this.refresh();

        // console.log(query)
        // console.log(this.state.categories.length)
        this.updateSelectCategories()

    }

    updateSelectCategories() {
        let query = {};

        let search = decodeURI(this.props.history.location.search).replace('?', '').split('&');
        for (let param of search) {
            let [key, value] = param.split('=');
            query[key] = value;
        }

        for (let i = 0; i < this.state.categories.length; i++) {
            if (this.state.categories[i]._id == query.categoryId) {
                this.setState({ currentCategoryIndex: i, currentSubcategoryIndex: -1 })
                for (let j = 0; j < this.state.categories[i].subcategories.length; j++) {
                    if (this.state.categories[i].subcategories[j]._id == query.subcategoryId) {
                        this.setState({ currentCategoryIndex: i, currentSubcategoryIndex: j })
                    }
                }
            }
        }
    }

    async componentWillReceiveProps(nextProps) {
        // await this.setState(nextProps)
        this.updateSelectCategories()
    }

    render() {
        return (
            <div className='ProductsScreen'>
                {this.state.categoryEditorMode != '' ?
                    <Blocker>
                        <div className='category-editor'>
                            <CloseIcon className='close-icon' onClick={() => this.setState({ categoryEditorMode: '', editorCategoryImage: null, editorCategoryName: '', editorCategoryShortName: '' })} />
                            <div className='title'>{this.state.categoryEditorMode}</div>
                            <div className='content'>
                                <div className='line'><div className='fieldname'>Pełna nazwa:</div> <input type="text" value={this.state.editorCategoryName} onChange={e => this.setState({ editorCategoryName: e.target.value })} /></div>
                                <div className='line'><div className='fieldname'>Skrócona nazwa: </div><input type="text" value={this.state.editorCategoryShortName} onChange={e => this.setState({ editorCategoryShortName: e.target.value })} /></div>
                                {this.state.categoryEditorMode.includes('Katego') ?
                                    <React.Fragment>
                                        <div className='line'>
                                            <div className='fieldname'> Zdjęcie kategori</div>
                                            <input type="file" onChange={this.onChangeFile} />
                                        </div>
                                        <div className='category-image'>
                                            <img id='category-img' src={this.state.editorCategoryImg || (config.SERVER_URL + '/public/categories/' + this.state.editorCategoryImage)} alt="&nbsp;&nbsp; Zdjecie Kategori &nbsp;&nbsp;" />

                                        </div>
                                    </React.Fragment> : null}
                            </div>
                            <div className='actions'>
                                <div className='save-button' onClick={this.saveCategoryChanges}>Zapisz zmiany</div>
                            </div>
                        </div>
                    </Blocker> : null
                }

                <div className='header'>
                    <div className='title'>Produkty</div>
                    <div className='overlay'></div>
                </div>

                <div className='container'>

                    <div className='categories'>
                        <div className='header'>
                            <div className='title' onClick={() => this.setState({ showCategories: !this.state.showCategories })}>
                                Kategorie

                                {this.state.showCategories ?
                                    <DownIcon className='updown-icon' />
                                    :
                                    <UpIcon className='updown-icon' />
                                }
                            </div>
                            <div className='actions'>
                                {this.context.user?.accountType == 'Administrator' ?
                                    <Tooltip description='Dodaj Kategorię'><AddIcon onClick={() => this.addCategoryHandler()} className='add-icon' /> </Tooltip>
                                    : null
                                }
                            </div>
                        </div>
                        <div className={'content' + (this.state.showCategories ? '' : ' hidden')}>

                            {this.state.categories.map((category, index) => {
                                return (
                                    <div className={'category' + ((this.state.currentCategoryIndex == index && this.state.currentSubcategoryIndex < 0) ? ' selected' : '')} key={index} onClick={(e) => { e.stopPropagation(); this.changeProductCategory(index, -1); this.setState({ showCategories: false }) }}>
                                        <div className='header-category'>
                                            <div className='title'>{category.shortName}</div>
                                            <div className='actions'>
                                                {this.context.user?.accountType == 'Administrator' ?
                                                    <React.Fragment>
                                                        <Tooltip description='Dodaj Podkategorię'> <AddIcon onClick={(e) => { e.stopPropagation(); this.addSubcategoryHandler(index) }} className='add-icon' /> </Tooltip>
                                                        <Tooltip description='Edytuj'> <EditIcon onClick={(e) => { e.stopPropagation(); this.editCategoryHandler(index) }} className='edit-icon' /> </Tooltip>
                                                        <Tooltip description='Usuń'> <DeleteIcon onClick={(e) => { e.stopPropagation(); this.deleteCategory(index) }} className='delete-icon' /> </Tooltip>
                                                    </React.Fragment> : null
                                                }

                                            </div>
                                        </div>
                                        <div className='subcategories'>
                                            {category.subcategories.map((subcategory, indexSub) => {
                                                return (
                                                    <div className={'subcategory' + ((this.state.currentCategoryIndex == index && this.state.currentSubcategoryIndex == indexSub) ? ' selected' : '')} key={indexSub} onClick={(e) => { e.stopPropagation(); this.changeProductCategory(index, indexSub); this.setState({ showCategories: false }) }}>
                                                        <div className='title'>{subcategory.shortName}</div>
                                                        <div className='actions'>
                                                            {this.context.user?.accountType == 'Administrator' ?
                                                                <React.Fragment>
                                                                    <Tooltip description='Edytuj'> <EditIcon onClick={(e) => { e.stopPropagation(); this.editSubcategoryHandler(index, indexSub) }} className='edit-icon' /> </Tooltip>
                                                                    <Tooltip description='Usuń'> <DeleteIcon onClick={(e) => { e.stopPropagation(); this.deleteSubcategory(index, indexSub) }} className='delete-icon' /> </Tooltip>
                                                                </React.Fragment> : null
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })}

                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                    </div>

                    <div className='products'>
                        <div className='header'>
                            <div className='title'>

                                <span class='category-link' onClick={() => this.changeProductCategory(-1, -1)}>Produkty</span>
                                {this.state.currentCategoryIndex >= 0 ?
                                    <React.Fragment>
                                        <span className='spacer'> &gt;  </span>

                                        <span class='category-link' onClick={() => this.changeProductCategory(this.state.currentCategoryIndex, -1)} >{this.state.categories[this.state.currentCategoryIndex]?.shortName}</span>

                                        {this.state.currentSubcategoryIndex >= 0 ?
                                            <React.Fragment>
                                                <span className='spacer'> &gt; </span>

                                                <span>{this.state.categories[this.state.currentCategoryIndex]?.subcategories[this.state.currentSubcategoryIndex].shortName}</span>
                                            </React.Fragment> : null
                                        }
                                    </React.Fragment>
                                    : null
                                }

                            </div>

                            <div className='search'>
                                <input type='search' onKeyPress={(e) => e.key === 'Enter' ? this.search() : null} placeholder='Szukaj...' value={this.state.searchPhraseInput} onChange={(e) => this.setState({ searchPhraseInput: e.target.value })} />
                                <div className='search-button' onClick={this.search}>Szukaj <SearchIcon className='search-icon' /></div>
                            </div>
                        </div>

                        <div className='content'>
                            {this.state.useSearch ?
                                <div className='using-filters'>Wyniki są filtrowane po słowie: {this.state.searchPhrase} <button onClick={this.clearSearch}>Wyczyść Filtry</button></div> : null
                            }

                            {this.state.products.length == 0 ?
                                <div className='no-products'>Brak produktów w tej kategorii</div> : null
                            }


                            {this.state.products.map((product, index) => {
                                return (

                                    <Link to={{ pathname: '/product/' + product.productName.replaceAll(' ', '-'), search: '?id=' + product._id }} className='product' key={index}>
                                        {/* <div className='product'> */}
                                        <div className='photo'>
                                            <img src={config.SERVER_URL + '/public/product-photos/' + product.productPhoto} alt='' />
                                        </div>
                                        <div className='product-info'>
                                            <div className='manufacturer'>{product.manufacturer}</div>
                                            <div className='name'>{product.productName}</div>
                                            <div className='description'>
                                                {product.description}
                                            </div>
                                        </div>
                                        {/* </div> */}
                                    </Link>
                                )
                            })}

                            {this.context.user?.accountType == 'Administrator' && this.state.currentCategoryIndex >= 0 && this.state.currentSubcategoryIndex >= 0 ?
                                <div className='add-product-container'>
                                    <Link to={{
                                        pathname: '/product',
                                        params: {
                                            category: this.state.categories[this.state.currentCategoryIndex]?._id,
                                            subcategory: this.state.categories[this.state.currentCategoryIndex]?.subcategories[this.state.currentSubcategoryIndex]._id
                                        }
                                    }}>
                                        <div className='add-product'>
                                            Dodaj Produkt
                                        </div>
                                    </Link>
                                </div> : null
                            }


                        </div>
                        <div className='pagination'>
                            {this.renderPagination()}
                        </div>
                    </div>

                </div>
            </div >
        );
    }

    search = async () => {
        await this.setState({ searchPhrase: this.state.searchPhraseInput, skip: 0 })
        this.refresh();
    }

    clearSearch = async () => {
        await this.setState({ useSearch: false, searchPhrase: '', searchPhraseInput: '', skip: 0 })
        this.refresh();
    }

    async refresh() {
        console.log('refresh')
        this.setState({ categoryEditorMode: '', products: [] })
        try {
            let { data } = await Axios.get('/categories');

            this.setState({ categories: data.categories });
            console.log(data)

        }
        catch (err) {
            console.log(err);
        }

        let historyQuery = {};
        let search = decodeURI(this.props.history.location.search).replace('?', '').split('&');

        for (let param of search) {
            let [key, value] = param.split('=');
            historyQuery[key] = value;
        }

        if (this.state.searchPhrase) {
            this.setState({ useSearch: true })
        }
        else {
            this.setState({ useSearch: false })
        }

        try {
            let query = `/products?limit=${this.state.limit}&skip=${this.state.skip * this.state.limit}&search=all[${this.state.searchPhrase}]`;
            if (historyQuery.categoryId) {
                query += `,category[${historyQuery.categoryId}]`;

                if (historyQuery.subcategoryId) {
                    query += `,subcategory[${historyQuery.subcategoryId}]`;
                }
            }
            console.log(query)
            window.scroll(5, 0, 0);
            let { data } = await Axios.get(query)
            // let { data } = await Axios.get(`/products?search=category[${this.state.categories[this.state.currentCategoryIndex]?._id}],subcategory[${this.state.categories[this.state.currentCategoryIndex]?.subcategories[this.state.currentSubcategoryIndex]._id}]`);
            console.log(data)
            await this.setState({ products: data.products || [], totalCount: data.totalCount });
        }
        catch (err) {
            console.log(err);
        }
    }

    addCategoryHandler = () => {
        this.setState({ categoryEditorMode: 'Dodaj Kategorię', editorCategoryName: '', editorCategoryShortName: '' })
        this.resolver = this.addCategory;
    }

    editCategoryHandler = (index) => {
        this.setState({ categoryEditorMode: 'Edytuj Kategorię', editorCategoryName: this.state.categories[index].name, editorCategoryShortName: this.state.categories[index].shortName, editorCategoryImage: this.state.categories[index].photoUrl })
        this.resolver = this.editCategory.bind(this, index);
    }

    addSubcategoryHandler = (index) => {
        this.setState({ categoryEditorMode: 'Dodaj Subkategorię', editorCategoryName: '', editorCategoryShortName: '' })
        this.resolver = this.addSubcategory.bind(this, index);
    }

    editSubcategoryHandler = (index, subIndex) => {
        this.setState({ categoryEditorMode: 'Edytuj Subkategorię', editorCategoryName: this.state.categories[index].subcategories[subIndex].name, editorCategoryShortName: this.state.categories[index].subcategories[subIndex].shortName })
        this.resolver = this.editSubcategory.bind(this, index, subIndex);
    }

    addCategory = async () => {

        const formData = new FormData();
        formData.append('file', this.state.editorCategoryImage);
        formData.append('name', this.state.editorCategoryName);
        formData.append('shortName', this.state.editorCategoryShortName);

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        try {
            let result = await Axios.post('/categories', formData, config);
            console.log(result)
            this.refresh();
            this.handleSuccess(result.data.message)
        }
        catch (err) {
            this.handleError(err);
        }

    }

    deleteCategory = async (categoryIndex) => {

        if (window.confirm('Czy na pewno chcesz usunąć tą kategorię, wszystkie podkategorie w niej zawarte i produkty zawarte w nich?')) {
            try {
                let result = await Axios.delete(`/categories/${this.state.categories[categoryIndex]._id}`);
                this.refresh();
                this.handleSuccess(result.data.message)
            }
            catch (err) {
                this.handleError(err);
            }
        }
    }

    editCategory = async (categoryIndex) => {
        const formData = new FormData();
        formData.append('file', this.state.editorCategoryImage);
        formData.append('name', this.state.editorCategoryName);
        formData.append('shortName', this.state.editorCategoryShortName);

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        try {
            let result = await Axios.put(`/categories/${this.state.categories[categoryIndex]._id}`, formData, config);
            this.refresh();
            this.handleSuccess(result.data.message)

        }
        catch (err) {
            this.handleError(err);
        }
    }

    addSubcategory = async (categoryIndex) => {
        try {
            let result = await Axios.post(`/categories/${this.state.categories[categoryIndex]._id}/subcategories`, { name: this.state.editorCategoryName, shortName: this.state.editorCategoryShortName });
            this.refresh();
            this.handleSuccess(result.data.message)
        }
        catch (err) {
            this.handleError(err);
        }
    }

    deleteSubcategory = async (categoryIndex, subcategoryIndex) => {
        if (window.confirm('Czy na pewno chcesz usunąć tą podkategorię, i wszystkie zawarte w niej produkty?')) {
            try {
                let result = await Axios.delete(`/categories/${this.state.categories[categoryIndex]._id}/subcategories/${this.state.categories[categoryIndex].subcategories[subcategoryIndex]._id}`);
                this.refresh();
                this.handleSuccess(result.data.message)
            }
            catch (err) {
                this.handleError(err);
            }
        }
    }

    editSubcategory = async (categoryIndex, subcategoryIndex) => {
        try {
            let result = await Axios.put(`/categories/${this.state.categories[categoryIndex]._id}/subcategories/${this.state.categories[categoryIndex].subcategories[subcategoryIndex]._id}`, { name: this.state.editorCategoryName, shortName: this.state.editorCategoryShortName });
            this.refresh();
            this.handleSuccess(result.data.message)
        }
        catch (err) {
            this.handleError(err);
        }
    }

    saveCategoryChanges = async () => {
        this.resolver();
        // if (this.state.editorCategoryName != '' && this.state.editorCategoryShortName != '') {
        //     this.resolver();
        // }
        // else {
        //     alert('Nazwa i skrócona nazwa nie mogą być puste!');
        // }
    }

    onChangeFile = (e) => {
        this.setState({ editorCategoryImage: e.target.files[0] })
        console.log(e.target)
        var oFReader = new FileReader();
        oFReader.readAsDataURL(e.target.files[0]);

        oFReader.onload = function (oFREvent) {
            document.getElementById("category-img").src = oFREvent.target.result;
        };
    }

    changeProductCategory = async (catIndex, subIndex) => {
        await this.setState({ currentCategoryIndex: catIndex, currentSubcategoryIndex: subIndex, skip: 0 })

        let pathname = '/products';
        let search = '?';
        if (catIndex >= 0) {
            pathname += '/' + this.state.categories[catIndex].shortName.replaceAll(' ', '-')
            search += `categoryId=${this.state.categories[catIndex]._id}`
            if (subIndex >= 0) {
                pathname += '/' + this.state.categories[catIndex].subcategories[subIndex].shortName.replaceAll(' ', '-');
                search += `&subcategoryId=${this.state.categories[catIndex].subcategories[subIndex]._id}`;
            }
        }

        await this.props.history.push({
            pathname,
            search
        })

        this.refresh()
    }

    handleError = async (err) => {
        console.log(err)
        let error = err.response?.data?.error
        console.log(typeof error)
        if (typeof error == 'object') {
            error = error.map(el => el.msg).join(', ')
        }
        console.log(error)
        this.context.messageBox({ message: error, className: 'error' })
    }

    handleSuccess = async (message) => {
        this.context.messageBox({ message })
    }

    renderPagination = () => {
        if (this.state.totalCount <= this.state.limit) return null;
        let pages = []
        for (let i = 0; i < this.state.totalCount / this.state.limit; i++) {
            pages.push((
                <div key={i} class={'pagination-page' + (this.state.skip == i ? ' selected' : '')} onClick={async () => { await this.setState({ skip: i }); this.refresh() }}>{i + 1}</div>
            ))
        }
        return pages;
    }
}

export default ProductsScreen;