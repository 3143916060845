import React, { Component } from 'react';

import GlobalContext from '../../context/GlobalContext';
import config from '../../config/config';
import Axios from 'axios';


import './SignIn.scss';


class SignInScreen extends Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props)

        this.state = {
            login: '',
            password: '',
            error: ''
        }
    }

    render() {
        return (
            <div className='SignInScreen'>
                <div className='container'>
                    <div className='form'>
                        <div className='header'>
                            Zaloguj się
                        </div>
                        <div className='content'>
                            <input type='text' placeholder='Login' value={this.state.login} onChange={e => this.setState({ login: e.target.value })} />
                            <input type='password' placeholder='Hasło' value={this.state.password} onChange={e => this.setState({ password: e.target.value })} />
                            <button className='sign-in-button' onClick={this.signIn}>Zaloguj</button>

                            {this.state.error ?
                                <div className='sign-in-error'>
                                    {this.state.error}
                                </div> : null
                            }
                        </div>
                    </div>

                </div>
            </div>
        );
    }

    signIn = () => {
        this.setState({ error: '' });

        let sendData = {
            login: this.state.login,
            password: this.state.password
        }

        Axios.post('/auth/sign-in?session=true', sendData)
            .then(({ data }) => {
                this.props.signIn(data.user);
                this.props.history.goBack();

            })
            .catch(err => {
                console.log(err)
                this.setState({ error: err.response?.data?.error || '' })
            })
    }

}

export default SignInScreen;