import React, { Component } from 'react';

import GlobalContext from '../../context/GlobalContext';
import config from '../../config/config';
import Axios from 'axios';


import './Settings.scss';


class SettingsScreen extends Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.state = {
            currentPassword: '',
            newPassword: '',
            repeatNewPassword: ''
        }
    }

    render() {
        return (
            <div className='SettingsScreen'>
                <div className='container'>
                    <div className='title'>Ustawienia</div>
                    <div className='content'>

                        <div className='box change-password'>
                            <div className='box-title'>Zmień Hasło</div>
                            <div className='box-content'>
                                <label> Obecne hasło: <input type='password' value={this.state.currentPassword} onChange={(e) => this.setState({ currentPassword: e.target.value })} /></label>
                                <label> Nowe hasło: <input type='password' value={this.state.newPassword} onChange={(e) => this.setState({ newPassword: e.target.value })} /></label>
                                <label> Powtórz nowe hasło: <input type='password' value={this.state.repeatNewPassword} onChange={(e) => this.setState({ repeatNewPassword: e.target.value })} /></label>
                                <button onClick={this.changePassword}> Zmień Hasło</button>
                            </div>
                        </div>

                        <div className='box backup-now'>
                            <div className='box-title'>Kopia Zapasowa Bazy Danych</div>
                            <div className='box-content'>
                                <span>
                                    Kopia zapasowa bazy danych wykonywana jest automatycznie co 24h <br />
                                    UWAGA! Obecnie nie wykonuje się kopia zapasowa zdjęć i załączonych plików.
                                </span>
                                <button onClick={this.backupNow}>Zrób Kopię Bazy Danych Teraz</button>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        );
    }

    backupNow = async () => {
        try {
            let result = await Axios.post('/administration/backup-now');
            this.handleSuccess(result.data.message);
        }
        catch (err) {
            this.handleError(err);
        }
    }

    changePassword = async () => {
        if (this.state.newPassword != this.state.repeatNewPassword) {
            this.context.messageBox({ message: 'Nowe hasło i powtórzone nowe hasło są różne', className: 'error' })
            return;
        }
        try {
            let result = await Axios.post('/auth/change-password', { currentPassword: this.state.currentPassword, newPassword: this.state.newPassword });
            this.handleSuccess(result.data.message);
        }
        catch (err) {
            this.handleError(err);
        }
    }

    handleError = async (err) => {
        console.log(err)
        let error = err.response?.data?.error
        console.log(typeof error)
        if (typeof error == 'object') {
            error = error.map(el => el.msg).join(', ')
        }
        console.log(error)
        this.context.messageBox({ message: error, className: 'error' })
    }

    handleSuccess = async (message) => {
        this.context.messageBox({ message })
    }
}

export default SettingsScreen;