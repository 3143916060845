
import config from '../config/config';

import React from 'react';
import { BrowserRouter, Route, Switch, Redirect, Link } from 'react-router-dom';

import axios from 'axios';

import GlobalContext from '../context/GlobalContext';


import Header from '../components/Header/Header';
import MessageBox from '../components/MessageBox/MessageBox';

import MainScreen from '../screens/Main/Main';
import ProductsScreen from '../screens/Products/Products';
import ProductScreen from '../screens/Product/Product';
import NewsScreen from '../screens/News/News';
import ArticleScreen from '../screens/Article/Article';
import SettingsScreen from '../screens/Settings/Settings';
import Page404 from '../screens/Page404/Page404';
import SignInScreen from '../screens/SignIn/SignIn';


import './App.scss';


axios.defaults.baseURL = config.SERVER_URL + '/api';
axios.defaults.withCredentials = true;

let logOut_FUN;
axios.interceptors.response.use((response) => {
  // console.log('response', response);
  // console.log('csrf', response.data._csrf);
  axios.defaults.headers.post['X-CSRF-Token'] = response.data._csrf;
  return response;
}, (error) => {
  // console.log('response err', error);
  if (error.response.data.action == 'logout') {
    logOut_FUN()
  }
  return Promise.reject(error);
});



class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      user: null,
      loaded: false,
      messageBox: null


    }

    this.messageBoxTimer = null;
  }

  async componentDidMount() {
    try {
      let result = await axios.post('/auth/refresh')
      // console.log(result.data.user);
      this.setState({ user: result.data.user })
      this.setState({ loaded: true })
    }
    catch (err) {
      this.setState({ loaded: true })
    }

  }

  render() {
    if (!this.state.loaded)
      return (<React.Fragment>Loading Page...</React.Fragment>);

    return (

      <div className="App">
        <GlobalContext.Provider value={{
          messageBox: this.messageBox, user: this.state.user, logOut: () => {
            logOut_FUN = () => {
              this.setState({ user: null })
            }
          }
        }}>


          <BrowserRouter>
            <Header user={this.state.user} logOut={this.logOut} settings={null} />

            <Switch>
              <Route exact path='/' component={(props) => <MainScreen {...props} />} />
              <Route path='/products' component={ProductsScreen} />
              <Route path='/product' component={(props) => <ProductScreen {...props} />} />
              <Route path='/news' component={(props) => <NewsScreen {...props} />} />
              <Route path='/article' component={(props) => <ArticleScreen {...props} />} />
              <Route path='/sign-in' component={(props) => <SignInScreen {...props} signIn={this.signIn} />} />
              <Route path='/settings' component={SettingsScreen} />

              {/* <Redirect exact from='/start' to='/' /> */}
              <Route component={Page404} />

            </Switch>

            <footer>
              <div className='container'>
                <div className='navigator'>
                  <Link to={{ pathname: '/' }}><div className='link'>Strona Główna</div></Link>
                  <Link to={{ pathname: '/', hash: 'Informations' }}><div className='link'>Informacje</div></Link>
                  <Link to={{ pathname: '/products' }}><div className='link'>Produkty</div></Link>
                  <Link to={{ pathname: '/news' }}><div className='link'>Nowości</div></Link>
                  <Link to={{ pathname: '/', hash: 'Downloads' }}><div className='link'>Do pobrania</div></Link>
                  <Link to={{ pathname: '/', hash: 'About-Us' }}><div className='link'>O nas</div></Link>
                  <Link to={{ pathname: '/', hash: 'Contact' }}><div className='link'>Kontakt</div></Link>
                  <Link to={{ pathname: '/sign-in' }}><div className='link'>Logowanie</div></Link>
                </div>
                <div className='company-name'>
                  THD Zufin Technologie 5 - osiowe
                </div>
              </div>
            </footer>
          </BrowserRouter>

        </GlobalContext.Provider>

        {
          this.state.messageBox ?
            <MessageBox className={this.state.messageBox.className}>
              {this.state.messageBox.message}
            </MessageBox>
            : null
        }

      </div >

    );
  }


  messageBox = (event) => {
    clearTimeout(this.messageBoxTimer)
    this.setState({ messageBox: null })

    this.setState({ messageBox: event });

    this.messageBoxTimer = setTimeout(() => {
      this.setState({ messageBox: null })
    }, 2500)
  }

  signIn = (user) => {
    console.log(user);
    this.setState({ user });

    // axios.post('/auth/refresh')
  }

  logOut = () => {
    axios.post('/auth/logout')
      .then(result => {
        if (!result.data.authenticated)
          this.setState({ user: null })
      })
      .catch(err => {
        //TODO: ERROR BOX
        console.log(err);
      })

  }
}


export default App;


