import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import GlobalContext from '../../context/GlobalContext';
import config from '../../config/config';
import Axios from 'axios';

import { AiOutlineSearch as SearchIcon } from 'react-icons/ai';


import './News.scss';

import articlePhoto from '../../assets/img_balldrive.jpg';

// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
// import ImageResize from 'quill-image-resize-module-react';
// import { Quill } from 'react-quill';
// Quill.register('modules/imageResize', ImageResize);
// // const MyEditor = () => {
// //   const [value, setValue] = useState('');

// //   return (
// //     <ReactQuill theme="snow" value={value} onChange={setValue}/>
// //   );
// // }

// let modules = {
//     imageResize: {
//         parchment: Quill.import('parchment'),
//         modules: ['Resize', 'DisplaySize', 'Toolbar']
//     },
//     toolbar: [
//         [{ 'header': [] }, { font: [] }],
//         ['bold', 'italic', 'underline', 'strike', 'blockquote'],
//         [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
//         ['link', 'image', 'video'],
//         ['clean'],
//         [{ 'color': [] }, { 'background': [] }]
//     ],
// }


class NewsScreen extends Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props)

        this.state = {
            articles: [],

            limit: 10,
            skip: 0,
            searchPhrase: ''
        }
    }

    componentDidMount() {
        window.scroll(5, 0, 0);

        this.refresh();
    }

    render() {
        return (
            <div className='NewsScreen'>

                <div className='header'>
                    <div className='title'>Nowości</div>
                    <div className='overlay'></div>
                </div>

                <div className='container'>

                    <div className='margin-container'>
                        <div className='articles'>
                            <div className='header'>

                            </div>
                            <div className='content'>
                                {this.context.user?.accountType == 'Administrator' ?
                                    <Link to={{ pathname: '/article' }} className='add-article'>
                                        Dodaj Artykuł
                                    </Link> : null
                                }
                                {this.state.articles.map((article, index) => {
                                    var temp = document.createElement('div');
                                    temp.innerHTML = article.content;
                                    let content = temp.textContent || temp.innerText || '';
                                    return (
                                        <Link key={index} to={{ pathname: `/article/${article.title.replaceAll(' ', '-')}`, search: '?id=' + article._id }}>
                                            <div className='article'>
                                                <div className='info'>
                                                    <div className='line'>
                                                        <div className='date'>{(new Date(article.date).toLocaleDateString())}</div>
                                                        <div className='author'> {article.author ? `(${article.author})` : ''}</div>
                                                    </div>
                                                    <div className='title'>{article.title}</div>
                                                    <div className='content'>{content}</div>
                                                </div>
                                                <div className='photo'>
                                                    <img src={article.articlePhoto ? (config.SERVER_URL + '/public/article-photos/' + article.articlePhoto) : articlePhoto} />
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                })}
                                <div className='pagination'>
                                    {this.renderPagination()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }

    refresh = async () => {
        try {
            let query = `/articles?limit=${this.state.limit}&skip=${this.state.skip * this.state.limit}&search=all[${this.state.searchPhrase}]`;
            // if (historyQuery.categoryId) {
            //     query += `,category[${historyQuery.categoryId}]`;

            //     if (historyQuery.subcategoryId) {
            //         query += `,subcategory[${historyQuery.subcategoryId}]`;
            //     }
            // }
            console.log(query)
            let { data } = await Axios.get(query)
            console.log(data)
            this.setState({ articles: data.articles || [], totalCount: data.totalCount });
        }
        catch (err) {
            console.log(err);
        }
    }

    renderPagination = () => {
        if (this.state.totalCount <= this.state.limit) return null;
        let pages = []
        for (let i = 0; i < this.state.totalCount / this.state.limit; i++) {
            pages.push((
                <div key={i} class={'pagination-page' + (this.state.skip == i ? ' selected' : '')} onClick={async () => { await this.setState({ skip: i }); this.refresh(); window.scroll(5, 0, 0) }}>{i + 1}</div>
            ))
        }
        return pages;
    }

}

export default NewsScreen;