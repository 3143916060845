import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import GlobalContext from '../../context/GlobalContext';

import { IoIosExit as LogoutIcon } from 'react-icons/io';
import { AiTwotoneSetting as SettingsIcon } from 'react-icons/ai';
import { GiHamburgerMenu as HamburgerIcon } from 'react-icons/gi';

import logo from '../../assets/cropped-Logo_THDZufin-removebg-preview.png';
import profilePhoto from '../../assets/profile-photo.png';


import './Header.scss';
import Tooltip from '../Tooltip/Tooltip';


class Header extends Component {
    static contextType = GlobalContext

    constructor(props) {
        super(props)

        this.state = {
            headerClass: false,
            showFullNav: false,
        }
    }

    componentDidMount() {
        this.context.logOut();
        // this.handleScroll();
        // window.addEventListener('scroll', this.handleScroll)
    }

    componentWillUnmount() {
        // window.removeEventListener('scroll', this.handleScroll)
    }

    render() {
        console.log(window.location.pathname)
        return (
            <header className={'Header' + (this.state.headerClass && window.location.pathname == '/' ? ' header-style-2' : '')}>
                <div className='container'>
                    <Link to={{ pathname: '/' }}>
                        <img className='logo' src={logo} alt="THD Zufin" />
                    </Link>

                    <div>
                        <nav className={this.state.showFullNav ? '' : 'hidden'}>
                            <Link to={{ hash: '', pathname: '/' }}><div onClick={this.openFullNav} className='link'>Strona Główna</div></Link>
                            <Link to={{ pathname: '/products' }}><div onClick={this.openFullNav} className='link'>Produkty</div></Link>
                            <Link to={{ pathname: '/news' }}><div onClick={this.openFullNav} className='link'>Nowości</div></Link>
                            <Link to={{ hash: 'Downloads', pathname: '/' }}><div onClick={this.openFullNav} className='link'>Do Pobrania</div></Link>
                            <Link to={{ hash: 'About-Us', pathname: '/' }}><div onClick={this.openFullNav} className='link'>O nas</div></Link>
                            <Link to={{ hash: 'Contact', pathname: '/' }}><div onClick={this.openFullNav} className='link'>Kontakt</div></Link>

                        </nav>
                        <HamburgerIcon className='hamburger-icon' onClick={this.openFullNav} />
                    </div>

                    {this.props.user ?
                        <React.Fragment>
                            <div className='user'>
                                <div className='photo'>
                                    <img src={profilePhoto} alt='' />
                                </div>
                                <div className='user-data'>
                                    <div className='login'>{this.props.user.login}</div>
                                    <div className='bottom-container'>
                                        <div className='account-type'>   {this.props.user.accountType}</div>
                                        <Tooltip description='Ustawienia'>
                                            <Link to={{ pathname: '/settings' }}>
                                                <SettingsIcon className='settings-icon' />
                                            </Link>
                                        </Tooltip>
                                        <Tooltip description='Wyloguj'>
                                            <LogoutIcon className='logout-icon' onClick={this.props.logOut} />
                                        </Tooltip>
                                    </div>

                                </div>
                            </div>
                        </React.Fragment>
                        :
                        null
                    }
                </div>
            </header >
        );
    }

    handleScroll = (e) => {
        if (window.scrollY > 80) {
            this.setState({ headerClass: false })
        }
        else {
            this.setState({ headerClass: true })
        }
    }

    openFullNav = () => {
        this.setState({ showFullNav: !this.state.showFullNav })
    }
}

export default Header;