import React, { Component } from 'react';

import './MessageBox.scss';



class MessageBox extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let classes = 'MessageBox';

        if (this.props.error) {
            classes += ' error'
        }

        return (
            <div className={classes + ' ' + this.props.className}>
                {this.props.children}
            </div>
        );
    }
}

export default MessageBox;