import React, { Component } from 'react';


import './Page404.scss';


class Page404 extends Component {
    render() {
        let message = decodeURI(this.props.location.search);
        message = message.replace('?message=', '')

        return (
            <div className='Page404'>

                <div className='box'>
                    {message ?
                        <div className='message'>
                            <div className='title'>Błąd: </div>
                            {message}
                        </div>
                        :
                        <div className='message'>
                            <div className='title'>Błąd: </div>
                            404 Page. Bad Reques
                        </div>

                    }

                </div>

            </div>
        );
    }
}

export default Page404;