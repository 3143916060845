import React, { Component } from 'react';

import './Slider.scss';

class Slider extends Component {
    constructor(props) {
        super(props)

        this.timer = null;
        this.state = {
            current: 0,

        }
    }

    componentDidMount() {
        //TODO: clearInterval when is not seen

        this.timer = setInterval(() => {
            let current = this.state.current;
            current++;

            if (current > this.props.photos.length - 1) {
                current = 0
            }
            this.setState({ current })
        }, this.props.duration || 3000)

    }

    componentWillUnmount() {
        clearInterval(this.timer)
    }
    render() {
        return (
            <div className={'Slider ' + this.props.className} style={this.props.style}>

                {
                    this.props.photos.map((src, index) => {

                        return <img key={'Slider' + index} src={src} alt={src} className={index === this.state.current ? 'opacity photos' : 'photos'
                        } />
                    })
                }
                <div className='blocker' style={this.props.blockerStyle}></div>
                <div className='children'>{this.props.children}</div>

            </div>
        );
    }


}

export default Slider;