import React, { Component } from 'react';

import GlobalContext from '../../context/GlobalContext';
import config from '../../config/config';
import Axios from 'axios';

import { AiOutlineSearch as SearchIcon } from 'react-icons/ai';


import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
import { Quill } from 'react-quill';

import MessageBox from '../../components/MessageBox/MessageBox';


import './Article.scss';


Quill.register('modules/imageResize', ImageResize);


// let modules = {
//     imageResize: {
//         parchment: Quill.import('parchment'),
//         modules: ['Resize', 'DisplaySize', 'Toolbar']
//     },

//     toolbar: [
//         [{ 'header': [] }, { font: [] }],
//         ['bold', 'italic', 'underline', 'strike', 'blockquote'],
//         [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
//         ['link', 'image', 'video'],
//         ['clean'],
//         [{ 'color': [] }, { 'background': [] }]
//     ],
// }

const modules = {
    toolbar: [

        [{ 'header': [1, 2, 3, false] }, { font: [] }],

        ['bold', 'italic', 'underline', 'strike'],                                                      // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }, { 'align': [] }],
        [{ 'script': 'sub' }, { 'script': 'super' }],                                                       // superscript/subscript
        [{ 'direction': 'rtl' }],                                                                         // text direction,

        [{ 'color': [] }, { 'background': [] }],                                                            // dropdown with defaults from theme

        ['link', 'image', 'video'],

        ['clean']                                                                                       // remove formatting button
    ],
    imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
    }
}


class ArticleScreen extends Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props)

        this.state = {
            article: {
                title: '',
                date: new Date(),
                content: '',
                author: '',
                articlePhoto: ''
            },
            editMode: false,

            messageBox: null,

            newArticlePhoto: '',
            newArticlePhotoPreview: '',


            pdfToHtml: ''
        }
    }

    componentDidMount() {
        window.scroll(5, 0, 0);

        this.refresh();

        // var iframe = document.getElementById("myIframe");

        // Adjusting the iframe height onload event
        // iframe.onload = function () {
        //     iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';
        // }
    }

    render() {

        let background = {}

        if (this.state.article?.articlePhoto) {
            background = { backgroundImage: `url("${config.SERVER_URL + '/public/article-photos/' + this.state.article.articlePhoto}")` }
        }
        if (this.state.newArticlePhotoPreview)
            background = { backgroundImage: `url("${this.state.newArticlePhotoPreview}")` }

        return (
            <div className='ArticleScreen'>

                <div className='header' style={background}>
                    <div className='title'>{this.state.article?.title}</div>
                    <div className='overlay'></div>

                    {this.context.user?.accountType == 'Administrator' ?

                        <div className='actions'>
                            {this.state.editMode ?
                                <React.Fragment>
                                    <button className='delete' onClick={this.deleteArticle}>Usuń</button>
                                    <button className='save' onClick={this.saveArticle}>Zapisz</button>
                                    <button className='edit' onClick={() => this.setState({ editMode: false })}> Podgląd</button>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <button className='edit' onClick={() => this.setState({ editMode: true })}>Edytuj</button>
                                </React.Fragment>
                            }
                        </div> : null
                    }
                </div>

                <div className='container'>

                    {this.state.editMode ?

                        <div className='editor'>


                            <div className='article-data'>
                                <div className='line'>
                                    <label className='title'>
                                        Tytuł:
                                        <input type='text' value={this.state.article?.title} onChange={this.changeArticleProp.bind(this, 'title')} />
                                    </label>
                                    <label className='date'>
                                        Data:
                                        <input type='date' value={this.getDate(this.state.article.date)} onChange={this.changeArticleProp.bind(this, 'date')} />
                                    </label>
                                </div>
                                <div className='line'>
                                    <label className='author'>
                                        Autor:
                                        <input type='text' value={this.state.article?.author} onChange={this.changeArticleProp.bind(this, 'author')} />
                                    </label>
                                    <label className='article-photo'>
                                        Zdjęcie:
                                        <input type='file' onChange={this.onChangeArticlePhoto} />
                                    </label>
                                </div>
                                <div className='line'>
                                    <div className='pdf-to-html'>
                                        <input type='file' onChange={(e) => this.setState({ pdfToHtml: e.target.files[0] })} />

                                        <button onClick={this.convertPdfToHtml}>Załaduj artykuł Z PDF</button>
                                    </div>
                                </div>
                            </div>

                            <div className='article-content'>
                                <ReactQuill theme="snow" modules={modules} value={this.state.article.content} onChange={this.updateText} />
                            </div>
                        </div>

                        :
                        <div className='article'>
                            <div className='header'>
                                <div className='date'>{(new Date(this.state.article?.date).toLocaleDateString())}</div>
                                <div className='author'>{this.state.article.author ? `(${this.state.article.author})` : ''}</div>
                            </div>
                            <div className='content'>
                                {/* {this.state.article.content} */}
                                <ReactQuill readOnly theme={null} modules={{}} value={this.state.article?.content} />
                            </div>
                        </div>
                    }

                </div>
                {
                    this.state.messageBox ?
                        <MessageBox className={this.state.messageBox.className}>
                            {this.state.messageBox.message}
                        </MessageBox>
                        : null
                }
            </div >
        );
    }

    updateText = (value) => {
        // console.log(value)
        let article = { ...this.state.article };
        article['content'] = value;
        this.setState({ article })
    }

    changeArticleProp = (field, e) => {
        let article = { ...this.state.article };
        article[field] = e.target.value;
        // if (field == 'date') {
        //     article[field] = new Date(e.target.value)
        // }
        this.setState({ article })
    }

    saveArticle = async () => {
        function DataURIToBlob(dataURI) {
            const splitDataURI = dataURI.split(',')
            const byteString = splitDataURI[0].indexOf('base64') >= 0 ? window.atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
            const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

            const ia = new Uint8Array(byteString.length)
            for (let i = 0; i < byteString.length; i++)
                ia[i] = byteString.charCodeAt(i)

            return new Blob([ia], { type: mimeString })
        }

        const formData = new FormData();
        formData.append('articlePhoto', this.state.newArticlePhoto);

        let article = { ...this.state.article }

        let content = article.content;
        for (let i = 0; content.includes('data:image'); i++) {
            const UID = Date.now() + '-' + Math.random();
            let image = content.match(/data:image[^"]+/)[0]
            formData.append('$image:' + UID, DataURIToBlob(image));
            // console.log(image)
            content = content.replace(/data:image[^"]+/, '$image:' + UID)
        }

        // console.log(content)
        article.content = content;
        let sendData = {
            article
        }
        console.log(sendData)
        formData.append('json', JSON.stringify(sendData));

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        try {
            let result;
            if (this.state.article._id) {
                result = await Axios.put('/articles/' + this.state.article._id, formData, config);
            }
            else {
                result = await Axios.post('/articles', formData, config);

                await this.props.history.push({
                    pathname: '/article/' + this.state.article.title.replaceAll(' ', '-'),
                    search: '?id=' + result.data._id
                })

            }

            this.refresh();
            this.handleSuccess(result.data.message);
            this.setState({ editMode: false })
        }
        catch (err) {
            this.handleError(err);
        }
    }

    deleteArticle = async () => {
        try {
            let result = await Axios.delete('/articles/' + this.state.article._id)
            await this.handleSuccess(result.data.message)
            this.props.history.push('/news')
        }
        catch (err) {
            this.handleError(err);
        }
    }

    refresh = async () => {

        // try {
        //     let { data } = await Axios.get('/c');

        //     this.setState({ categories: data.categories });
        // }
        // catch (err) {
        //     console.log(err);
        // }

        let id = decodeURI(this.props.history.location.search).replace('?id=', '')

        if (!id) return;

        try {
            let { data } = await Axios.get(`/articles/${id}`);

            let article = data.articles[0];
            // article.date = new Date(article.date)
            // console.log(article)
            this.setState({ article })
        }
        catch (err) {
            console.log(err);
        }
    }

    handleError = async (err) => {
        console.log(err)
        let error = err.response?.data?.error
        console.log(typeof error)
        if (typeof error == 'object') {
            error = error.map(el => el.msg).join(', ')
        }
        this.messageBox({ message: error, className: 'error' })
    }

    handleSuccess = async (message) => {
        this.messageBox({ message })
    }

    messageBox = (event) => {
        clearTimeout(this.messageBoxTimer)
        this.setState({ messageBox: null })

        this.setState({ messageBox: event });

        this.messageBoxTimer = setTimeout(() => {
            this.setState({ messageBox: null })
        }, 2500)
    }

    onChangeArticlePhoto = (e) => {
        this.setState({ newArticlePhoto: e.target.files[0] })

        var reader = new FileReader();
        reader.onloadend = () => {
            this.setState({ newArticlePhotoPreview: reader.result })
        }
        reader.readAsDataURL(e.target.files[0]);

    }

    getDate = (date) => {
        date = new Date(date).toISOString()
        date = date.split('T')[0];
        date = date.split('-')
        // console.log(date)
        // let d = new Date(date)
        // let year = d.getFullYear();
        // let month = d.getMonth();
        // let day = d.getDate()

        // if (month < 10) {
        //     month = '0' + month;
        // }
        // if (day < 10) {
        //     day = '0' + day;
        // }
        // date = date.split('T')[0]
        // date = date.split('.')
        // console.log(date)
        // return '2022-02-02'
        // return `${year}-${month}-${day}`;
        return `${date[0]}-${date[1]}-${date[2]}`;
    }

    convertPdfToHtml = async () => {
        const formData = new FormData();
        formData.append('pdf', this.state.pdfToHtml);

        let sendData = {

        }

        formData.append('json', JSON.stringify(sendData));

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        try {
            let { data } = await Axios.post('/articles/pdf-to-html', formData, config);
            console.log(data.html)
            if (data.html) {
                let article = { ...this.state.article };
                article.content = data.html;
                this.setState({ article });
            }


        }
        catch (err) {
            this.handleError(err);
        }
    }
}

export default ArticleScreen;