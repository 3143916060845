import React, { Component } from 'react';

import GlobalContext from '../../context/GlobalContext';
import config from '../../config/config';
import Axios from 'axios';
import ContentEditable from 'react-contenteditable'

import photo1 from '../../assets/products/TWA-130faceplate-1-min.png';
import productItem1 from '../../assets/products/RWB-400R-1-1300x975-min.png';

import { AiOutlineSearch as SearchIcon } from 'react-icons/ai';
import { FaFileDownload as DownloadIcon } from 'react-icons/fa';
import { GoPrimitiveSquare as BoxIcon } from 'react-icons/go';

import { AiFillDelete as DeleteIcon } from 'react-icons/ai';
import { IoMdAdd as AddIcon } from 'react-icons/io';

import Tooltip from '../../components/Tooltip/Tooltip';
import MessageBox from '../../components/MessageBox/MessageBox';


import './Product.scss';


class ProductScreen extends Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props)

        this.state = {
            headerOpacity: 0,

            editMode: false,
            product: {
                _id: '',
                manufacturer: '',
                productName: '',
                description: '',
                productPhoto: '',
                accessories: '',
                specification: '',
                models: '',
                features: [],

                category: '',
                subcategory: ''
            },

            newProductPhoto: '',
            newProductSpecification: '',
            newProductAccessories: '',

            categories: [],
            productCategory: '',
            productSubcategory: '',

            messageBox: null
        }

        this.messageBoxTimer = null;
    }

    async componentDidMount() {
        window.scroll(5, 0, 0);

        window.addEventListener('scroll', this.handleScroll)

        console.log(this.props.history.location.params)
        if (this.props.history.location?.params) {
            let product = { ...this.state.product };
            product.category = this.props.history.location.params.category;
            product.subcategory = this.props.history.location.params.subcategory;
            this.setState({ product })
        }

        this.refresh();
    }

    async componentWillReceiveProps(nextProps) {
        return false;
        // if (nextProps != this.props) {
        //     console.log('next p')
        //     await this.setState(nextProps);
        //     this.refresh()
        // }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    render() {
        return (
            <div className='ProductScreen'>

                <div className={'header' + ((this.context.user?.accountType == 'AdministratorAAAA') ? '' : ' play-animation')}>
                    <div className='header-container'>
                        {this.context.user?.accountType == 'Administrator' ?
                            <div className='update-actions'>
                                {/* <button></button> */}
                                {this.state.editMode ?
                                    <React.Fragment>
                                        <select value={this.state.product.category} onChange={this.productCategoryChangeHandler}>
                                            <option disabled> - Kategorie - </option>
                                            {this.state.categories.map((category, index) => {
                                                return (
                                                    <option value={category._id}>{category.shortName}</option>
                                                )
                                            })}
                                        </select>

                                        <select value={this.state.product.subcategory} onChange={this.productSubcategoryChangeHandler}>
                                            <option disabled> - Podkategorie - </option>
                                            {this.state.categories.filter(el => el._id == this.state.product.category)[0]?.subcategories.map((subcategory, index) => {
                                                return (
                                                    <option value={subcategory._id}>{subcategory.shortName}</option>
                                                )
                                            })}
                                        </select>
                                        <button onClick={this.copyProduct}>Kopiuj</button>
                                        <button onClick={this.deleteProduct}>Usuń</button>
                                        <button onClick={this.saveProduct}>Zapisz</button>
                                        <button onClick={() => this.setState({ editMode: false })}>Podgląd</button>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <button onClick={() => this.setState({ editMode: true })}>Edytuj</button>
                                    </React.Fragment>
                                }

                            </div> : null
                        }
                        <div className='product-photo'>
                            {this.state.editMode ?
                                <React.Fragment>
                                    <input type='file' onChange={this.onChangeProductPhoto} />
                                </React.Fragment> : null
                            }
                            <img id='product-photo' src={this.state.newProductPhoto || config.SERVER_URL + '/public/product-photos/' + this.state.product.productPhoto} />

                        </div>
                        <div className='content'>
                            <div className='manufacturer'>
                                {this.state.editMode ?
                                    <input placeholder='Producent' value={this.state.product.manufacturer} onChange={this.productChangeHandler.bind(this, 'manufacturer')} disabled={!this.state.editMode} />
                                    :
                                    <React.Fragment>
                                        {this.state.product.manufacturer}
                                    </React.Fragment>
                                }
                            </div>
                            <div className='product-name'>
                                {this.state.editMode ?
                                    <input placeholder='Produkt' value={this.state.product.productName} onChange={this.productChangeHandler.bind(this, 'productName')} disabled={!this.state.editMode} />
                                    :
                                    <React.Fragment>
                                        {this.state.product.productName}
                                    </React.Fragment>
                                }
                            </div>
                            <div className='description'>
                                <ContentEditable html={this.state.product.description} onChange={this.productChangeHandler.bind(this, 'description')} disabled={!this.state.editMode} />
                            </div>
                            <div className='actions'>


                                {this.state.editMode ?
                                    <React.Fragment>
                                        <label>Specyfikacja: {this.state.product.specification?.replace(/\d+-0.\d+-/i, '')}<br /> <br /><input type='file' onChange={this.onChangeProductSpecification} /></label>
                                    </React.Fragment>
                                    :
                                    <a target="_blank" rel="noopener noreferrer" href={config.SERVER_URL + '/public/product-specifications/' + this.state.product.specification}>
                                        <div className='download-docs'>
                                            <DownloadIcon className='download-icon' /> Pobierz specyfikację
                                        </div>
                                    </a>
                                }

                                {this.state.editMode ?
                                    <label>Akcesoria: {this.state.product.accessories?.replace(/\d+-0.\d+-/i, '')}<br /> <br /><input type='file' onChange={this.onChangeProductAccessories} /></label>
                                    :
                                    <a target="_blank" rel="noopener noreferrer" href={config.SERVER_URL + '/public/product-accessories/' + this.state.product.accessories}>
                                        <div className='accessories'>
                                            Akcesoria
                                        </div>
                                    </a>
                                }

                            </div>
                        </div>

                    </div>
                    <div className='overlay' style={{ opacity: this.state.headerOpacity }}></div>
                </div>

                <div className={'product-description' + (this.context.user?.accountType == 'AdministratorAAA' ? '' : ' play-animation')}>
                    <div className='container'>
                        {this.state.product.models || this.state.editMode ?
                            <div className='models'>
                                <div className='content'>
                                    <img id='product-photo' src={this.state.newProductPhoto || config.SERVER_URL + '/public/product-photos/' + this.state.product.productPhoto} />

                                    <div className='models-list'>
                                        <div className='models-title'>Modele:</div>
                                        {this.state.editMode ?
                                            <textarea type='text' placeholder='Modele' value={this.state.product.models} onChange={this.productChangeHandler.bind(this, 'models')} disabled={!this.state.editMode} />
                                            :
                                            <div>{this.state.product.models}</div>
                                        }
                                    </div>
                                </div>
                            </div> : null
                        }

                        <div className='features'>
                            {this.state.product.features.map((feature, index) => {
                                return (
                                    <div className='feature-item' key={index}>
                                        <div className='title'>
                                            <span className='group'>
                                                <BoxIcon className='box-icon' />
                                                {this.state.editMode ?
                                                    <input value={feature.title} onChange={this.editFeatureTitle.bind(this, index)} />
                                                    :
                                                    <div>{feature.title}</div>
                                                }
                                            </span>
                                            {this.state.editMode ?
                                                <span className='actions'>
                                                    <Tooltip description='Usuń Sekcje'>
                                                        <DeleteIcon className='delete-icon' onClick={() => this.deleteFeature(index)} />
                                                    </Tooltip>
                                                </span> : null
                                            }
                                        </div>
                                        <div className='feature-description'>
                                            <ContentEditable className='content-editable' html={feature.description} disabled={!this.state.editMode} onChange={this.editFeatureDescription.bind(this, index)} />
                                        </div>
                                    </div>
                                )
                            })}

                            {this.state.editMode ?
                                <div className='add-feature-container'>
                                    <div className='add-feature' onClick={this.addFeature}> <AddIcon className='add-icon' /> Dodaj Sekcje</div>
                                </div>
                                : null
                            }

                        </div>
                    </div>
                </div>
                {
                    this.state.messageBox ?
                        <MessageBox className={this.state.messageBox.className}>
                            {this.state.messageBox.message}
                        </MessageBox>
                        : null
                }
            </div >
        );
    }

    refresh = async () => {

        try {
            let { data } = await Axios.get('/categories');

            this.setState({ categories: data.categories });
        }
        catch (err) {
            console.log(err);
        }

        let id = decodeURI(this.props.history.location.search).replace('?id=', '')
        // console.log(id)
        if (!id) return;

        try {
            let { data } = await Axios.get(`/products/${id}`);
            this.setState({ product: data.products[0] })
        }
        catch (err) {
            console.log(err);
        }
    }

    handleScroll = (e) => {
        this.setState({ headerOpacity: window.scrollY / 500 })
    }

    productChangeHandler = async (field, e) => {
        function convertToPlain(html) {
            var tempDivElement = document.createElement("div");
            tempDivElement.innerHTML = html;
            return tempDivElement.textContent || tempDivElement.innerText || "";
        }

        console.log(e)
        let product = { ...this.state.product };
        product[field] = convertToPlain(e.target.value);
        await this.setState({ product })

        console.log(this.state.product)
    }

    addFeature = async () => {
        let product = { ...this.state.product };
        product.features.push({ title: '', description: '' })
        this.setState({ product })
    }


    deleteFeature = async (index) => {
        let product = { ...this.state.product };
        product.features.splice(index, 1);
        this.setState({ product })
    }

    editFeatureTitle = async (index, e) => {
        let product = { ...this.state.product };
        product.features[index].title = e.target.value
        this.setState({ product })
    }

    editFeatureDescription = async (index, e) => {
        let product = { ...this.state.product };
        product.features[index].description = e.target.value
        this.setState({ product })
    }

    onChangeProductPhoto = (e) => {
        this.setState({ newProductPhoto: e.target.files[0] })

        var oFReader = new FileReader();
        oFReader.readAsDataURL(e.target.files[0]);

        oFReader.onload = function (oFREvent) {
            let imgs = document.querySelectorAll('#product-photo');
            console.log(imgs)
            imgs.forEach(img => img.src = oFREvent.target.result)
            // document.getElementById("product-photo").src = oFREvent.target.result;
        };
    }

    onChangeProductSpecification = (e) => {
        this.setState({ newProductSpecification: e.target.files[0] })

    }

    onChangeProductAccessories = (e) => {
        this.setState({ newProductAccessories: e.target.files[0] })

    }

    deleteProduct = async () => {
        if (window.confirm('Czy na pewno chcesz usunąć produkt')) {
            try {
                let result = await Axios.delete('/products/' + this.state.product._id);
                this.props.history.push('/products')
                this.handleSuccess(result.data.message);
            }
            catch (err) {
                this.handleError(err)
            }
        }
    }

    saveProduct = async () => {

        const formData = new FormData();
        formData.append('productPhoto', this.state.newProductPhoto);
        formData.append('specification', this.state.newProductSpecification);
        formData.append('accessories', this.state.newProductAccessories);

        let sendData = {
            product: this.state.product
        }

        formData.append('json', JSON.stringify(sendData));

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        try {
            let result;
            if (this.state.product._id) {
                result = await Axios.put('/products/' + this.state.product._id, formData, config);
            }
            else {
                result = await Axios.post('/products', formData, config);

                await this.props.history.push({
                    pathname: '/product/' + this.state.product.productName,
                    search: '?id=' + result.data._id
                })
                // this.setState({ product })
            }

            this.refresh();
            this.handleSuccess(result.data.message);
            this.setState({ editMode: false })
        }
        catch (err) {
            this.handleError(err);
        }
    }

    productCategoryChangeHandler = async (e) => {
        let product = { ...this.state.product };
        product.category = e.target.value
        let subcategory = this.state.categories.filter(el => el._id == this.state.product.category)[0]?.subcategories[0];
        product.subcategory = subcategory
        this.setState({ product })
    }

    productSubcategoryChangeHandler = async (e) => {
        let product = { ...this.state.product };
        product.subcategory = e.target.value
        this.setState({ product })
    }

    handleError = async (err) => {
        console.log(err)
        let error = err.response?.data?.error
        console.log(typeof error)
        if (typeof error == 'object') {
            error = error.map(el => el.msg).join(', ')
        }
        console.log(error)
        this.messageBox({ message: error, className: 'error' })
    }

    handleSuccess = async (message) => {
        this.messageBox({ message })
    }

    copyProduct = async () => {
        let product = { ...this.state.product };
        delete product._id;
        product.productName += '-kopia'
        this.setState({ product, editMode: true })
    }

    messageBox = (event) => {
        clearTimeout(this.messageBoxTimer)
        this.setState({ messageBox: null })

        this.setState({ messageBox: event });

        this.messageBoxTimer = setTimeout(() => {
            this.setState({ messageBox: null })
        }, 2500)
    }
}

export default ProductScreen;