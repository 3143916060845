import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';

import Header from '../../components/Header/Header';
import GlobalContext from '../../context/GlobalContext';


import config from '../../config/config';
import Tooltip from '../../components/Tooltip/Tooltip';
import Slider from '../../components/Slider/Slider';

import pdf1 from '../../assets/download-files/Katalog główny Tsudakoma wersja Angielska.png';
import pdf2 from '../../assets/download-files/Katalog główny Tsudakoma wersja Niemiecka.png';
import pdf3 from '../../assets/download-files/Katalog główny Pionner wersja Niemiecka.png';

import video1 from '../../assets/videos/RG Promotion.mp4';
import video2 from '../../assets/videos/BDS_cycletime2014_E.mov';

import photo1 from '../../assets/Laga.png';
import jakub from '../../assets/Jakub-300x300.png';
import wideLogo from '../../assets/Loga2-removebg-preview.png';
import productItem1 from '../../assets/products/RWB-400R-1-1300x975-min.png';
import productItem2 from '../../assets/products/TWA-130faceplate-1-min.png';
import productItem3 from '../../assets/products/TWM-100ps-copy-1300x867-min.png';

import { RiLifebuoyLine as SupportIcon } from 'react-icons/ri';
import { AiFillWechat as ChatIcon } from 'react-icons/ai';
import { ImCheckmark as TickIcon } from 'react-icons/im';
import { AiFillLinkedin as LinkedInIcon } from 'react-icons/ai';
import { BiChevronDown as ShowMoreIcon } from 'react-icons/bi';
import { AiFillFilePdf as PDFIcon } from 'react-icons/ai';


import './Main.scss';

const videos = [
    {
        src: video1,
        name: 'RG Promotion.mp4'
    },
    {
        src: video2,
        name: 'BDS_cycletime2014_.mp4'
    }
]


class MainScreen extends Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props)

        this.state = {
            categories: [],
            currentVideoIndex: 0
        }

        this.sectionsRefs = {}
    }

    async componentDidMount() {
        await this.refresh();
        this.scrollToSection(this.props.location.hash);
    }

    componentWillReceiveProps = (props) => {
        this.scrollToSection(props.location.hash)
    }

    render() {
        return (
            <div className='MainScreen'>


                <div className='section-introduction wrap afterwrap' ref={ref => this.sectionsRefs[''] = ref}>
                    <div className='overlay'></div>
                    <div className='start-info'>
                        <div className='title'>Witamy w THD Zufin!</div>
                        <div className='subtitle'>Zapraszamy do zapoznania się z naszą ofertą</div>
                    </div>
                    <Link to={{ hash: 'Informations', pathname: '/' }}>
                        <div className='show-more'>
                            <span>Zobacz więcej</span><ShowMoreIcon className='show-more-icon' />
                        </div>
                    </Link>
                </div>


                <div className='overview' ref={ref => this.sectionsRefs['Informations'] = ref}>
                    <div className='container'>

                        <div className='subsection'>
                            <div className='header'>
                                <div className='title'>Dlaczego nasze rozwiązania są najlepsze?</div>
                                <div className='subtitle'>Poznaj szczegóły naszej produkcji i wsparcia technicznego</div>
                            </div>

                            <div className='content'>
                                <div className='item'>
                                    <div className='photo photo-icon'><ChatIcon className='chat-icon icon' /></div>
                                    <div className='title'>Doświadczenie</div>
                                    <div className='description'>
                                        Od 1909 -Historia japońskich technologii wytwarzania oparta na najwyższych standardach produkcji.
                                        NONOICHI - Najwyższy, światowy poziom zdolności produkcyjnych- produkcja powierzchni 18 720 m2
                                        jest największą na świecie wytwarzającą stoły obrotowe NC
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='photo photo-icon'><TickIcon className='tick-icon icon' /></div>
                                    <div className='title'>Jakość</div>
                                    <div className='description'>
                                        W procesie produkcji wykorzystuje się precyzyjne maszyny współrzędnościowe, które wymagają ścisłej kontroli
                                        temperatury przy zastosowaniu komór w których utrzymywana jest stała temperatura 20 st. C. Dodatkowo w całym
                                        Zakładzie kontrolowany komputerowo jest poziom temperatury.
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='photo photo-icon'><SupportIcon className='support-icon icon' /></div>
                                    <div className='title'>Wsparcie</div>
                                    <div className='description'>
                                        Zespół naszych inżynierów zawsze gotowy jest do udzielenia wsparcia i pomocy, zarówno przy pracach aplikacyjnych,
                                        jak też przy usługach serwisowych.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='subsection products'>
                            <div className='header'>
                                <div className='title'>Czym się zajmujemy</div>
                            </div>

                            <div className='content'>
                                {
                                    this.state.categories.map((category, index) => {
                                        return (
                                            <div className='item' key={index}>
                                                <Link to={{ pathname: '/products/' + category.shortName.replaceAll(' ', '-'), search: `categoryId=${category._id}` }}>
                                                    <div className='photo'><img src={config.SERVER_URL + '/public/categories/' + category.photoUrl} alt='' /></div>
                                                </Link>
                                                <div className='title'>{category.name}</div>
                                                <div className='description'>
                                                    {category.subcategories.map((subcategory, subIndex) => {
                                                        return (
                                                            <Link
                                                                key={subIndex}
                                                                to={{
                                                                    pathname: '/products/' + category.shortName.replaceAll(' ', '-') + '/' + subcategory.shortName.replaceAll(' ', '-'),
                                                                    search: `categoryId=${category._id}&subcategoryId=${subcategory._id}`
                                                                }}>
                                                                <div className='category' dangerouslySetInnerHTML={{ __html: subcategory.name }}></div>
                                                            </Link>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                    </div>
                </div>

                {/* <div className='video-secion'>



                </div> */}

                <div className='company-info wrap'>
                    <div className='overlay'></div>
                    <div className='container afterwrap'>
                        <div className='content'>
                            <div className='title'>
                                O THD Zufin
                            </div>

                            <div className='description'>
                                Naszym najważniejszym celem jest dostarczanie wysokiej jakości, najnowocze- śniejszych technologii
                                w przystępnych cenach. Nasz asortyment obejmuje bogaty program stołów obrotowych największego japońskiego
                                producenta TSUDAKOMA oraz oprawek i akcesoriów CNC marki PIONEER.
                            </div>
                        </div>

                        <div className='video-section'>
                            <div className='videos'>
                                {videos.map((video, index) => {
                                    return (
                                        <video className={'video-src' + (this.state.currentVideoIndex == index ? '' : ' hidden')} autoPlay muted controls>
                                            <source src={video.src} type="video/mp4" />
                                        </video>
                                    )
                                })}
                            </div>
                            <div className='controls'>
                                {videos.map((video, index) => {
                                    return (
                                        <div className={'video-name' + (this.state.currentVideoIndex == index ? ' selected' : '')} onClick={() => this.setState({ currentVideoIndex: index })}>{video.name}</div>
                                    )
                                })}
                            </div>
                        </div>
                        {/* <img src={photo1} alt='THD ZUFIN, TSUD KOMA, PIONEER' /> */}
                        {/* <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/73McNWYnIMM?modestbranding=1&autohide=1&showinfo=0&controls=0" frameborder="0" allowfullscreen></iframe> */}
                    </div>
                </div>


                <div className='downloads' ref={ref => this.sectionsRefs['Downloads'] = ref} >
                    <div className='container afterwrap'>
                        <div className='header'>
                            <div className='title'>
                                Pliki do pobrania
                            </div>
                            {/* <div className='subtitle'></div> */}
                            <div className='files'>
                                <a href={config.SERVER_URL + '/public_static/to-download/Katalog główny Tsudakoma wersja Angielska.pdf'} target="_blank" rel="noopener noreferrer">
                                    <div className='file'>
                                        <div className='filename'>
                                            Katalog główny Tsudakoma (wersja Angielska)
                                        </div>
                                        {/* <iframe src={`${pdf1}#toolbar=0&embedded=true`} style={{ overflow: 'hidden' }}></iframe>
                                         */}
                                        <div className='file-content'>
                                            {/* <PDFIcon className='pdf-icon' /> */}
                                            <img src={pdf1} />
                                        </div>
                                    </div>
                                </a>

                                <a href={config.SERVER_URL + '/public_static/to-download/Katalog główny Tsudakoma wersja Niemiecka.pdf'} target="_blank" rel="noopener noreferrer">
                                    <div className='file'>
                                        <div className='filename'>
                                            Katalog główny Tsudakoma (wersja Niemiecka)
                                        </div>

                                        <div className='file-content'>
                                            {/* <PDFIcon className='pdf-icon' /> */}
                                            <img src={pdf2} />
                                        </div>
                                    </div>
                                </a>

                                <a href={config.SERVER_URL + '/public_static/to-download/Katalog główny Pionner wersja Niemiecka.pdf'} target="_blank" rel="noopener noreferrer">
                                    <div className='file'>
                                        <div className='filename'>
                                            Katalog główny Pionner (wersja Niemiecka)
                                        </div>
                                        <div className='file-content'>
                                            {/* <PDFIcon className='pdf-icon' /> */}
                                            <img src={pdf3} />
                                        </div>
                                    </div>
                                </a>

                            </div>
                        </div>

                    </div>
                </div>


                <div className='about-us wrap' ref={ref => this.sectionsRefs['About-Us'] = ref}>
                    <div className='container afterwrap'>
                        <div className='header'>
                            <div className='title'>
                                Poznaj nas
                            </div>
                            <div className='subtitle'>
                                Jesteśmy zgranym i doświadczonym zespołem specjalistów. Naszym zadaniem jest
                                dostarczanie Klientowi optymalnych i skutecznych rozwiązań w ramach zaawansowanych
                                technologii wytwarzania w procesach obróbki skrawaniem. Najważniejsze jest dla nas
                                zadowolenie Klientów. Nasz interdyscyplinarny zespół jest do Twojej dyspozycji.
                            </div>
                        </div>

                        <div className='content'>
                            <div className='person'>
                                <img src={jakub} alt='' />
                                <div className='personal-data'>
                                    <div className='fullname'>Jakub Niespodziański</div>
                                    <div className='position'>SPRZEDAŻ, WSPARCIE TECHNICZNE</div>
                                    <div className='description'>
                                        Blisko 20 letnie doświadczenie w zakresie zaawansowanych technologii wytwarzania
                                        to gwarancja profesjonalnej, branżowej wiedzy i rzetelnego wsparcia
                                    </div>
                                    <div className='social-media'>
                                        <a href='https://www.linkedin.com/in/jakub-niespodzianski-39323288/'>
                                            <LinkedInIcon className='linkedin-icon' />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <img className='wide-logo' src={wideLogo} alt='' />
                    </div>

                </div>


                <div className='contact' ref={ref => this.sectionsRefs['Contact'] = ref}>
                    <div className='container afterwrap'>
                        <div className='header'>
                            <div className='title'>
                                Kontakt
                            </div>
                            <div className='subtitle'>
                                Wsparcie, dostawa i serwis są świadczone na terenie całej Polski z siedziby głównej w Tychach.
                            </div>
                        </div>

                        <div className='content'>
                            <div className='map'>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d194661.83947993792!2d18.909085515315965!3d50.13002454503069!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4716b7e1a7d2ba99%3A0x8ff62c177d334da4!2sRolna%2037%2C%2043-100%20Tychy!5e0!3m2!1spl!2spl!4v1644858777912!5m2!1spl!2spl" ></iframe>
                            </div>

                            <div className='contacts'>
                                <div className='contact-info'>
                                    <div className='line color'>THD ZUFIN</div>
                                    <div className='line bold'>ul.Rolna 37/16</div>
                                    <div className='line bold'>43-100 Tychy</div>
                                    <div className='line bold'>NIP: 646-204-91-04</div>
                                </div>

                                <div className='contact-info'>
                                    <div className='line color'>Jakub Niespodziański</div>
                                    <div className='line bold'>mobile: +48 795 157 301</div>
                                    <div className='line bold'>e-mail: biuro@thdzufin.pl</div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }

    scrollToSection(name) {
        name = name.replace('#', '')
        let section = this.sectionsRefs[name];

        if (section) {
            window.scrollTo(60, section.offsetTop - 150)
        }

    }

    async refresh() {
        console.log('refresh')
        this.setState({ categoryEditorMode: '' })
        try {
            let { data } = await Axios.get('/categories');

            this.setState({ categories: data.categories });
            console.log(data)

        }
        catch (err) {
            console.log(err);
        }
    }
}

export default MainScreen;