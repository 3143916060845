import React, { Component } from 'react';


import './Tooltip.scss';

class Tooltip extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            top: 0,
            left: 0
        }
        this.ref = null;
        this.containerRef = null;
    }

    render() {
        let classes = 'description';
        if (this.props.description && this.state.show) {
            classes += ' visible';
        }
        return (

            <div className='Tooltip'
                onMouseOver={this.mouseOverHandler}
                onMouseOut={() => { this.setState({ show: false }) }}
                style={this.props.style}
            >
                <span className="children">
                    {this.props.children}
                </span>
                <div style={this.props.descriptionStyle} ref={(ref) => this.ref = ref}
                    className={classes}>
                    {this.props.description}
                </div>

            </div>
        );
    }

    mouseOverHandler = (e) => {
        this.setState({ show: true })
    }
}


export default Tooltip;