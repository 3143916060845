import React, { Component } from 'react';

import './Blocker.scss';
class Blocker extends Component {
    render() {
        return (
            <div className='Blocker'>
                {this.props.children}
            </div>
        );
    }
}

export default Blocker;